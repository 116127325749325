import React from "react";
import { Select } from "antd";
import styles from "./index.module.css";

const OrderFilters = ({ selectedOrderStatus, handleStatusChange }) => {
  return (
    <div className={styles.container}>
      <Select
        defaultValue={selectedOrderStatus}
        style={{ width: 120 }}
        className={styles.select_container}
        onChange={handleStatusChange}
        options={[
          { value: "filter by status", label: "Filter By Status" },
          { value: "placed", label: "Placed" },
          { value: "confirmed", label: "Confirmed" },
          { value: "cancelled", label: "Cancelled" },
          { value: "transit", label: "Transit" },
          { value: "delivered", label: "Delivered" },
        ]}
      />
    </div>
  );
};

export default OrderFilters;
