export const getOrdersByStatus = (orders, status) => {
  return [...orders].filter((order) => {
    if (status === "filter by status") return true;
    return order.status === status;
  });
};

export const getListOrderTitle = (selectedOrderStatus, filteredOrders) => {
  switch (selectedOrderStatus) {
    case "filter by status":
      return `All Orders (${filteredOrders.length})`;
    case "placed":
      return `Placed Orders (${filteredOrders.length})`;
    case "confirmed":
      return `Confirmed Orders (${filteredOrders.length})`;
    case "cancelled":
      return `Cancelled Orders (${filteredOrders.length})`;
    case "transit":
      return `Transit Orders (${filteredOrders.length})`;
    case "delivered":
      return `Delivered Orders (${filteredOrders.length})`;
    default:
      return "All Orders";
  }
};

export const initialState = {
  orders: [],
  loading: true,
  status: "placed",
  error: false,
  form: { name: "", description: "" },
};

export function reducer(state, action) {
  switch (action.type) {
    case "ADD_ORDER": {
      return { ...state, orders: [action.order, ...state.orders] };
    }
    case "RESET_FORM":
      return { ...state, form: initialState.form };
    case "SET_ORDERS":
      return { ...state, orders: action.orders, loading: false };
    case "SET_INPUT":
      return { ...state, form: { ...state.form, [action.name]: action.value } };

    case "UPDATE_ORDER": {
      const { orders } = state;
      const index = state.orders.findIndex((o) => o.id === action.order.id);

      if (index > -1) {
        orders[index].paymentStatus = action.order.paymentStatus;
        orders[index].status = action.order.status;
        orders[index].feedback = action.order.feedback;
      } else {
        orders.push(action.order);
      }

      return { ...state, orders };
    }
    case "SET_FILTER":
      return { ...state, status: action.status };
    case "ERROR":
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

export function getStartOfToday(selectedDay) {
  if (selectedDay === "today") {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  }

  if (selectedDay === "yesterday") {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);
    return yesterday;
  }
}

export function getEndOfToday(selectedDay) {
  if (selectedDay === "today") {
    const today = new Date();
    today.setHours(23, 59, 59, 999);
    return today;
  }

  if (selectedDay === "yesterday") {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(23, 59, 59, 999);
    return yesterday;
  }
}
