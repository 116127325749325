import React, { useContext } from "react";
import { capitalizeText, getDisplayPrice } from "../../utils";
import { getLocalTime } from "../../utils/query";
import { AppContext } from "../../Router";
import "./index.css";

const LatestOrders = ({ orders }) => {
  const { partner } = useContext(AppContext);
  const latestOrders = [...orders];

  const getOrderTotal = (order) => {
    return order.sales.reduce(
      (acc, curr) => acc + parseInt(curr.price, 10) * curr.quantity,
      0,
    );
  };

  const renderOrders = latestOrders
    .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    .map((order) => {
      const { id, orderNumber, orderSource, createdAt } = order;
      return (
        <tr key={id}>
          <td>#{orderNumber}</td>
          <td>{`${capitalizeText(orderSource.type)} ${orderSource.name}`}</td>
          <td>
            {getDisplayPrice({
              priceInPence: getOrderTotal(order),
              withCurrencySymbol: true,
              currencyConfig: partner.config.currencyConfig,
            })}
          </td>
          <td>{getLocalTime(createdAt)}</td>
        </tr>
      );
    });

  return (
    <section id="latest-orders">
      <h2>Latest Orders</h2>
      {latestOrders.length > 0 && (
        <table>
          <thead>
            <tr>
              <th>Number</th>
              <th>Location</th>
              <th>Amount</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>{renderOrders}</tbody>
        </table>
      )}
      {latestOrders.length === 0 && (
        <p className="text_center">No orders yet today 😃</p>
      )}
    </section>
  );
};

export default LatestOrders;
