import React from "react";
import "./OrderDetails.css";
import { getLocalTime, formatDate } from "../../utils/query";
import { capitalizeText, statuses } from "../../utils/";

const OrderDetails = ({ order, updateOrder, updatePaymentStatus }) => {
  let actions = [];
  const getMarkPaid = () => {
    return (
      <div className="mark-paid">
        <input
          type="checkbox"
          id="markPaid"
          checked={order.paymentDetails.status === "Paid"}
          onChange={() =>
            updatePaymentStatus(
              order.id,
              order.paymentDetails.status === "Paid" ? "Unpaid" : "Paid",
            )
          }
        />
        <label htmlFor="markPaid">
          Mark as {order.paymentDetails.status === "Paid" ? "Unpaid" : "Paid"}
        </label>
      </div>
    );
  };
  if (order.status === statuses.PLACED) {
    actions = [
      <button
        key={`${order.id}-1`}
        className="btn-reject"
        onClick={() => updateOrder(order.id, statuses.CANCELLED)}
      >
        Cancel
      </button>,
      <button
        key={`${order.id}-2`}
        className="btn-accept"
        onClick={() => updateOrder(order.id, statuses.CONFIRMED)}
      >
        Confirm
      </button>,
    ];
  } else if (order.status === statuses.CONFIRMED) {
    actions = [
      <button
        key={`${order.id}-4`}
        className="btn-reject"
        onClick={() => updateOrder(order.id, statuses.CANCELLED)}
      >
        Cancel order
      </button>,
      <button
        key={`${order.id}-3`}
        className="btn-accept"
        onClick={() => updateOrder(order.id, statuses.TRANSIT)}
      >
        Order in transit
      </button>,
    ];
  } else if (order.status === statuses.TRANSIT) {
    actions = [
      <button
        key={`${order.id}-5`}
        className="btn-accept"
        onClick={() => updateOrder(order.id, statuses.DELIVERED)}
      >
        {" "}
        Order delivered
      </button>,
    ];
  } else {
    actions = [];
  }
  return (
    <div className="container">
      <div className="order-details">
        <h3>Order #{order.orderNumber}</h3>
        <div className="status-container">
          <div>
            <p>
              <strong>Status:</strong>{" "}
              <span className={`${order.status}_order`}>{order.status}</span>
            </p>
            {order.updatedBy && (
              <p>
                <strong>Updated by:</strong>{" "}
                <span>{capitalizeText(order.updatedBy)}</span>
              </p>
            )}
          </div>
          <div>
            <p>
              <strong>Order Date:</strong> {formatDate(order.createdAt)}
            </p>
            <p>
              <strong>Order Time:</strong> {getLocalTime(order.createdAt)}
            </p>
          </div>
        </div>
        <div className="section">
          <h4>Sales Items</h4>
          {order.sales.map((item, index) => (
            <div className="sales-item" key={index}>
              <p>
                <strong>Product Name:</strong> {item.name}
              </p>
              <p>
                <strong>Quantity:</strong> {item.quantity}
              </p>
              <p>
                <strong>Price:</strong> ₦{(item.price / 100).toFixed(2)}
              </p>
            </div>
          ))}
        </div>
        <div className="flex-container">
          <div className="section">
            <h4>Payment Details</h4>
            <p>
              <strong>Amount:</strong> ₦
              {(order.paymentDetails.amount / 100).toFixed(2)}
            </p>
            <p>
              <strong>Method:</strong> {order.paymentDetails.method}
            </p>
            <p>
              <strong>Status:</strong> {order.paymentDetails.status}
            </p>
            {getMarkPaid()}
          </div>
          <div className="section">
            <h4>Customer Details</h4>
            <p>
              <strong>Name:</strong>{" "}
              {capitalizeText(order.customerDetails.name)}
            </p>
            {order.customerDetails.tableNumber && (
              <p>
                <strong>Table Number:</strong>{" "}
                {order.customerDetails.tableNumber}
              </p>
            )}
            {order.customerDetails.roomNumber && (
              <p>
                <strong>Room Number:</strong> {order.customerDetails.roomNumber}
              </p>
            )}
            {order.orderSource.sectionName && (
              <p>
                <strong>Section:</strong>{" "}
                <strong>{capitalizeText(order.orderSource.sectionName)}</strong>
              </p>
            )}
          </div>
        </div>
        <div className="actions">{actions}</div>
      </div>
    </div>
  );
};

export default OrderDetails;
