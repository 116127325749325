import React, { useContext, useReducer } from "react";
import { notification } from "antd";
import LatestOrders from "../LatestOrders";
import Overview from "../Overview";
import { AppContext } from "../../Router";
import {
  useFetchOrders,
  useOrderSubscription,
  useFetchQrcodeLinks,
  useQrcodeLinkSubscription,
} from "../../hooks";

const PENCE_IN_100 = 100;

const initialState = {
  orders: [],
  qrcodeLinks: [],
  loading: true,
  status: "placed",
  error: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "ADD_ORDER": {
      return {
        ...state,
        orders: [action.order, ...state.orders],
        loading: false,
      };
    }
    case "SET_ORDERS":
      return { ...state, orders: action.orders, loading: false };
    case "SET_QRCODE_LINKS":
      return { ...state, qrcodeLinks: action.qrcodeLinks, loading: false };
    case "ADD_QRCODE_LINK": {
      return {
        ...state,
        qrcodeLinks: [action.qrcodeLink, ...state.qrcodeLinks],
        loading: false,
      };
    }
    case "UPDATE_QRCODE_LINK": {
      const existingLinkIndex = state.qrcodeLinks.findIndex(
        (link) => link.qrcodeId === action.qrcodeLink.qrcodeId,
      );
      const updatedQrcodeLinks = [...state.qrcodeLinks];
      if (existingLinkIndex !== -1) {
        updatedQrcodeLinks[existingLinkIndex] = action.qrcodeLink;
      } else {
        updatedQrcodeLinks.unshift(action.qrcodeLink);
      }
      return {
        ...state,
        qrcodeLinks: updatedQrcodeLinks,
        loading: false,
      };
    }

    case "SET_INPUT":
      return { ...state, form: { ...state.form, [action.name]: action.value } };

    case "SET_FILTER":
      return { ...state, status: action.status };
    case "ERROR":
      return { ...state, loading: false, error: true };
    default:
      return state;
  }
}

const Dashboard = () => {
  const { partner, loading, userAttributes } = useContext(AppContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [api, contextHolder] = notification.useNotification();
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (!partner) return null;

  const { isLoading } = useFetchOrders({
    selectedDay: "today",
    dispatch,
    api: null,
    accountId: userAttributes["custom:accountId"],
    userAttributes,
    userLocation: userAttributes["custom:location"] || null,
  });
  useOrderSubscription({
    dispatch,
    userAttributes,
    api,
    accountId: userAttributes["custom:accountId"],
    userLocation: userAttributes["custom:location"] || null,
  });

  const { isLoadingQrcodeLinks } = useFetchQrcodeLinks({
    formattedDate: today.toISOString(),
    dispatch,
    slug: partner?.slug,
    api: null,
    userAttributes,
  });

  useQrcodeLinkSubscription({
    formattedDate: today.toISOString(),
    dispatch,
    slug: partner?.slug,
    api: null,
    userAttributes,
  });

  const getTotalsSales = (orders) => {
    return orders.reduce((acc, curr) => {
      return (
        acc +
        curr.sales.reduce((acc, curr) => {
          return (
            acc + (parseInt(curr.price, 10) * curr.quantity) / PENCE_IN_100
          );
        }, 0)
      );
    }, 0);
  };

  if (loading || isLoading || isLoadingQrcodeLinks)
    return <div>Loading...</div>;
  if (state.error) return <div>Error fetching data</div>;
  return (
    <main>
      {contextHolder}
      <Overview
        totalOrders={state.orders.length}
        orders={state.orders}
        totalSales={getTotalsSales(state.orders)}
        qrcodeLinks={state.qrcodeLinks}
      />
      {partner.config.ordering && <LatestOrders orders={state.orders} />}
    </main>
  );
};

export default Dashboard;
